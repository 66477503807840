// Override any Bootstrap variables here:
//   https://github.com/twbs/bootstrap/blob/v4.4.1/scss/_variables.scss

$primary: #2d9cdb !default;

$theme-colors: (
  "primary": #2d9cdb,
  "primary-shadow": rgba($primary, 0.5),
);

// $primary-shadow: #b48636 !default;
$input-color: #212529 !default;

$input-btn-focus-width: 1px;
$input-btn-focus-box-shadow: 0;
